@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

/* apply styling to all instead of defining separately where possible */
@layer base {
  * {
    @apply p-0;
    @apply m-0;
    @apply box-border;
  }

  body {
    @apply font-[Oswald];
    @apply bg-[black];
    /* @apply bg-gray-900; */
  }

  /* remove padding margins from headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply m-0;
    @apply p-0;
    @apply leading-none;
  }
}

/* Specific stylings below as needed or overriding Tailwind CSS*/
/* Body */
/* Hide scrollbar */
body::-webkit-scrollbar {
  display: none;
}
/* Contact Textarea */
textarea::-webkit-scrollbar {
  width: 0.25rem;
}

textarea::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 1em 0;
}

textarea::-webkit-scrollbar-thumb {
  background-color: white;
}

/* Landing Page Swiper Styles Overrides */
.LandingContainer,
.SwiperContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.SwiperSlideImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlayFilter {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.pulseRegisterButton {
  animation: pulseRegister 1200ms infinite;
}

@keyframes pulseRegister {
  0% {
    box-shadow: 0 0 0 0 rgba(217, 249, 157, 0.5);
  }
  70% {
    box-shadow: 0 0 0 12px rgba(217, 249, 157, 0);
  }
  100% {
  }
}

.pulseLoginButton {
  animation: pulseLogin 1200ms infinite;
}

@keyframes pulseLogin {
  0% {
    box-shadow: 0 0 0 0 rgba(251, 207, 232, 0.5);
  }
  70% {
    box-shadow: 0 0 0 12px rgba(251, 207, 232, 0);
  }
  100% {
  }
}

.pulseSendButton {
  animation: pulseSend 1200ms infinite;
}

@keyframes pulseSend {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  70% {
    box-shadow: 0 0 0 12px rgba(255, 255, 255, 0);
  }
  100% {
  }
}

/* NavBar Styles Overrides */
/* Navbar hover underlines and current active route underline */
.underline-red-landing::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 10px;
  width: 0%;
  background-color: #ff0000;
  transition: 200ms ease-in-out;
}

.underline-red-landing:hover::before,
.underline-red-landing:focus::before {
  width: 100%;
}

@media (max-width: 768px) {
  .underline-red-landing::before {
    bottom: -5px;
    height: 5px;
  }
}

.underline-red::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 5px;
  width: 0%;
  background-color: #ff0000;
  transition: 200ms ease-in-out;
}

.underline-red:hover::before,
.underline-red:focus::before {
  width: 100%;
}

.underline-red.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 5px;
  width: 100%;
  background-color: limegreen;
}

/* Modal for larger Images display Styles Overrides */
.modalBackdropUnderlay {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  /* background-color: rgba(31, 41, 55, 0.9); */
  backdrop-filter: blur(5px);
}
